import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Editor from "./Editor";

const Projects = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/auth/project');
        setData(data);
    };


    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    const [content, setContent] = useState({});
    const handleContentChange = (name, value) => {
        setContent(prevContent => ({ ...prevContent, [name]: value }));
    }

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">دەستپێک</a></li>
                    <li className="breadcrumb-item active" aria-current="page">پرۆژەکان</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">لیستی پرۆژەکان</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">پرۆژەیەکی نوێ</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">پرۆژەیەکی نوێ</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    formData.append('description_krd', content.description_krd);
                                                    formData.append('description_ar', content.description_ar);
                                                    formData.append('description_en', content.description_en);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/project`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('زیادکرا');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (کوردی)</label>
                                                            <input type="text" className="form-control" name="title_krd" placeholder="ناونیشان بە کوردی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (عەرەبی)</label>
                                                            <input type="text" className="form-control" name="title_ar" placeholder="ناونیشان بە عەرەبی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (ئینگلیزی)</label>
                                                            <input type="text" className="form-control" name="title_en" placeholder="ناونیشان بە ئینگلیزی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (کوردی)</label>
                                                            <Editor name="description_krd" targetTextareaId="description_krd" onContentChange={(value) => handleContentChange("description_krd", value)} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (عەرەبی)</label>
                                                            <Editor name="description_ar" targetTextareaId="description_ar" onContentChange={(value) => handleContentChange("description_ar", value)} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (ئینگلیزی)</label>
                                                            <Editor name="description_en" targetTextareaId="description_en" onContentChange={(value) => handleContentChange("description_en", value)} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">وێنە</label>
                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                            <input type="hidden" name="image" defaultValue={selectedFile} required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary">بڵاوکردن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>وێنە</th>
                                            <th>سەردێڕ</th>
                                            <th>رێککەوت</th>
                                            <th>بژاردە</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td><img src={`../../${item.image}`} alt={item.title_krd} className="img-fluid" /></td>
                                                    <td>{item.title_krd}</td>
                                                    <td>{new Date(item.created).toLocaleString()}</td>
                                                    <td>
                                                        <a href={`/blog/${item.id}`} target={'_blank'} rel="noopener noreferrer" className="btn btn-sm btn-outline-primary mx-1" onClick={() => { localStorage.setItem('page', 'blogs'); window.scrollTo(0, 0); }} >
                                                            <icon.Eye size="18" />
                                                        </a>
                                                        <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                            <icon.Edit size="18" />
                                                        </button>
                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                                            Swal.fire({
                                                                title: 'وریابە !',
                                                                text: "بە سڕینەوەی ئەم داتایە ناتونیت پاشگەزبیتەوە",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'بەلێ, سڕینەوە !',
                                                                cancelButtonText: 'نەخێر, داخستن!'
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/project/${item.id}`).then(() => {
                                                                        getData();
                                                                    })
                                                                }
                                                            })
                                                        }}>
                                                            <icon.Trash size="18" />
                                                        </button>
                                                    </td>
                                                    <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="exampleModalLabel">دەستکاریکردن</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                </div>
                                                                <form onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    const formData = new FormData(e.target);
                                                                    formData.append('description_krd', content.description_krd);
                                                                    formData.append('description_ar', content.description_ar);
                                                                    formData.append('description_en', content.description_en);
                                                                    const data = Object.fromEntries(formData);
                                                                    axios.put(`/project/${item.id}`, data).then(() => {
                                                                        window.$(`#edit${item.id}`).modal('hide');
                                                                        toast.success('نوێکرایەوە');
                                                                        getData();
                                                                    })
                                                                }} >
                                                                    <div className="modal-body">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناونیشان (کوردی)</label>
                                                                            <input type="text" className="form-control" name="title_krd" defaultValue={item.title_krd} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناونیشان (عەرەبی)</label>
                                                                            <input type="text" className="form-control" name="title_ar" defaultValue={item.title_ar} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناونیشان (ئینگلیزی)</label>
                                                                            <input type="text" className="form-control" name="title_en" defaultValue={item.title_en} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناوەڕۆک (کوردی)</label>
                                                                            <Editor name="description_ar" targetTextareaId="description_krd" defaultValue={item.description_krd} onContentChange={(value) => handleContentChange("description_krd", value)} />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناوەڕۆک (عەرەبی)</label>
                                                                            <Editor name="description_ar" targetTextareaId="description_ar" defaultValue={item.description_ar} onContentChange={(value) => handleContentChange("description_ar", value)} />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناوەڕۆک (ئینگلیزی)</label>
                                                                            <Editor name="description_en" targetTextareaId="description_en" defaultValue={item.description_en} onContentChange={(value) => handleContentChange("description_en", value)} />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">وێنە</label>
                                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                                            <input type="hidden" name="image" defaultValue={selectedFile} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                        <button type="submit" className="btn btn-primary">نوێکردنەوە</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Projects;