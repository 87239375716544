import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";

const Staff = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/auth/staff');
        setData(data);
    };


    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">دەستپێک</a></li>
                    <li className="breadcrumb-item active" aria-current="page">ستاف</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">ستاف</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">کارمەندێکی نوێ</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">کارمەندێکی نوێ</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/staff`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('زیادکرا');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">ناو (کوردی)</label>
                                                            <input type="text" className="form-control" name="name_krd" placeholder="ناو بە کوردی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناو (عەرەبی)</label>
                                                            <input type="text" className="form-control" name="name_ar" placeholder="ناو بە عەرەبی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناو (ئینگلیزی)</label>
                                                            <input type="text" className="form-control" name="name_en" placeholder="ناو بە ئینگلیزی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ڕۆڵ (کوردی)</label>
                                                            <input text="input" className="form-control" name="position_krd" placeholder="ڕۆڵ بە کوردی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ڕۆڵ (عەرەبی)</label>
                                                            <input text="input" className="form-control" name="position_ar" placeholder="ڕۆڵ بە عەرەبی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ڕۆڵ (ئینگلیزی)</label>
                                                            <input text="input" className="form-control" name="position_en" placeholder="ڕۆڵ بە ئینگلیزی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ژ.مۆبایل</label>
                                                            <input type="number" className="form-control" name="phone_no" placeholder="ژمارەی مۆبایل" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">وێنە</label>
                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                            <input type="hidden" name="image" defaultValue={selectedFile} required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary">بڵاوکردن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>وێنە</th>
                                            <th>ناو</th>
                                            <th>ڕۆل</th>
                                            <th>بژاردە</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td><img src={`../../${item.image}`} alt={item.name_krd} className="img-fluid" /></td>
                                                    <td>{item.name_krd}</td>
                                                    <td>{item.position_krd}</td>
                                                    <td>
                                                        <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                            <icon.Edit size="18" />
                                                        </button>
                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                                            Swal.fire({
                                                                title: 'وریابە !',
                                                                text: "بە سڕینەوەی ئەم داتایە ناتونیت پاشگەزبیتەوە",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'بەلێ, سڕینەوە !',
                                                                cancelButtonText: 'نەخێر, داخستن!'
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/staff/${item.id}`).then(() => {
                                                                        getData();
                                                                    })
                                                                }
                                                            })
                                                        }}>
                                                            <icon.Trash size="18" />
                                                        </button>
                                                    </td>
                                                    <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="exampleModalLabel">دەستکاریکردن</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                </div>
                                                                <form onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    const formData = new FormData(e.target);
                                                                    const data = Object.fromEntries(formData);
                                                                    axios.put(`/staff/${item.id}`, data).then(() => {
                                                                        window.$(`#edit${item.id}`).modal('hide');
                                                                        toast.success('نوێکرایەوە');
                                                                        getData();
                                                                    })
                                                                }} >
                                                                    <div className="modal-body">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناو (کوردی)</label>
                                                                            <input type="text" className="form-control" name="name_krd" defaultValue={item.name_krd} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناو (عەرەبی)</label>
                                                                            <input type="text" className="form-control" name="name_ar" defaultValue={item.name_ar} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناو (ئینگلیزی)</label>
                                                                            <input type="text" className="form-control" name="name_en" defaultValue={item.name_en} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ڕۆڵ (کوردی)</label>
                                                                            <input text="input" className="form-control" name="position_krd" defaultValue={item.position_krd} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ڕۆڵ (عەرەبی)</label>
                                                                            <input text="input" className="form-control" name="position_ar" defaultValue={item.position_ar} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ڕۆڵ (ئینگلیزی)</label>
                                                                            <input text="input" className="form-control" name="position_en" defaultValue={item.position_en} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ژ.مۆبایل</label>
                                                                            <input type="text" className="form-control" name="phone_no" defaultValue={item.phone_no} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">وێنە</label>
                                                                            <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                                            <input type="hidden" name="image" defaultValue={selectedFile} required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                        <button type="submit" className="btn btn-primary">نوێکردنەوە</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Staff;