import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";

const Sliders = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/auth/slider');
        setData(data);
        if (data.length > 0) {
            axios.get("/auth/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true
                        })
                    }
                }
            })
        }
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">دەستپێک</a></li>
                    <li className="breadcrumb-item active" aria-current="page">بلاگ</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">سلایدەکان</h6>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>وێنە</th>
                                            <th>سەردێڕ</th>
                                            <th>بژاردە</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td><img src={`../../${item.image}`} alt={item.caption_krd} className="img-fluid" /></td>
                                                    <td>{item.caption_krd}</td>
                                                    <td>
                                                        <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                            <icon.Edit size="18" />
                                                        </button>
                                                        <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalLabel">دەستکاریکردن</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                    </div>
                                                                    <form onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        const formData = new FormData(e.target);
                                                                        const data = Object.fromEntries(formData);
                                                                        axios.put(`/slider/${item.id}`, data).then(() => {
                                                                            window.$(`#edit${item.id}`).modal('hide');
                                                                            toast.success('نوێکرایەوە');
                                                                            getData();
                                                                        })
                                                                    }} >
                                                                        <div className="modal-body">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">سەردێڕی یەکەم (کوردی)</label>
                                                                                <input type="text" className="form-control" name="caption_krd" defaultValue={item.caption_krd} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">سەردێڕی یەکەم (عەرەبی)</label>
                                                                                <input type="text" className="form-control" name="caption_ar" defaultValue={item.caption_ar} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">سەردێڕێ یەکەم (ئینگلیزی)</label>
                                                                                <input type="text" className="form-control" name="caption_en" defaultValue={item.caption_en} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">سەردێڕی دووەم (کوردی)</label>
                                                                                <input type="text" className="form-control" name="title_krd" defaultValue={item.title_krd} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">سەردێڕی دووەم (عەرەبی)</label>
                                                                                <input type="text" className="form-control" name="title_ar" defaultValue={item.title_ar} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">سەردێڕی دووەم (ئینگلیزی)</label>
                                                                                <input type="text" className="form-control" name="title_en" defaultValue={item.title_en} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ناوەڕۆک (کوردی)</label>
                                                                                <textarea className="form-control" name="description_krd" col={3} defaultValue={item.description_krd} />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ناوەڕۆک (عەرەبی)</label>
                                                                                <textarea className="form-control" name="description_ar" col={3} defaultValue={item.description_ar} />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">ناوەڕۆک (ئینگلیزی)</label>
                                                                                <textarea className="form-control" name="description_en" col={3} defaultValue={item.description_en} />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">وێنە</label>
                                                                                <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                                                <input type="hidden" name="image" defaultValue={selectedFile} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                            <button type="submit" className="btn btn-primary">نوێکردنەوە</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Sliders;