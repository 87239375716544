import { useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import $ from "jquery";

const Editor = ({ name, onContentChange, targetTextareaId, defaultValue }) => {
    const [content, setContent] = useState(defaultValue || '');

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setContent(data);
        onContentChange(data);
    };

    useEffect(() => {
        $(`#${targetTextareaId}`).val(content);
    }, [content, targetTextareaId]);

    return (
        <CKEditor editor={ClassicEditor} name={name} data={content} onChange={handleEditorChange} />
    );
};

export default Editor;
