import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";

const General = ({ icon }) => {

    const [data, setData] = useState([]);
    const [id, setId] = useState(null);
    const getData = async () => {
        const { data } = await axios.get('/auth/general');
        setData(data);
        setId(data.id)
        if (data.length > 0) {
            axios.get("/auth/datatable").then((ready) => {
                if (ready.data !== undefined) {
                    if (window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                        window.$('.dt-tbl').DataTable();
                    } else {
                        window.$('.dt-tbl').DataTable({
                            responsive: true
                        })
                    }
                }
            })
        }
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, [id]);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">دەستپێک</a></li>
                    <li className="breadcrumb-item active" aria-current="page">گشتی</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">ویب سایت بەگشتی</h6>
                                </div>
                                <div className="col-md-12 d-flex">
                                    {console.log(id)}
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        const formData = new FormData(e.target);
                                        const data = Object.fromEntries(formData);
                                        axios.put(`/general/${id}`, data).then(() => {
                                            toast.success('زیادکرا');
                                            getData();
                                        })
                                    }} >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">ژ.مۆبایل ١</label>
                                                    <input type="text" className="form-control" name="phone_no1" defaultValue={data.phone_no1} required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">ژ.مۆبایل 2</label>
                                                    <input type="text" className="form-control" name="phone_no2" defaultValue={data.phone_no2} required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">پۆستی ئەلیکترۆنی</label>
                                                    <input type="text" className="form-control" name="email" defaultValue={data.email} required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">ناونیشان (کوردی)</label>
                                                    <input type="text" className="form-control" name="address_krd" defaultValue={data.address_krd} required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">ناونیشان (عەرەبی)</label>
                                                    <input type="text" className="form-control" name="address_ar" defaultValue={data.address_ar} required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">ناونیشان (ئینگلیزی)</label>
                                                    <input type="text" className="form-control" name="address_en" defaultValue={data.address_en} required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">لینکی نەخشەی گووگل</label>
                                                    <textarea className="form-control" name="maplink" rows={3} defaultValue={data.maplink} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">لۆگۆ</label>
                                                    <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" required />
                                                    <input type="hidden" name="logo" defaultValue={selectedFile} required />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">نوێکردنەوە</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default General;