import React, { useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import $ from "jquery";

const Sidebar = ({ icon }) => {
    const navigate = useNavigate();
    useEffect(() => {
        axios.get("/auth/auth").then((res) => {
            if (!res.data) {
                navigate('/login')
            }
        });
    }, []);

    return (
        <nav className="sidebar">
            <div className="sidebar-header">
                <Link to="/" className="sidebar-brand">
                    My<span> Konos</span>
                </Link>
                <div className="sidebar-toggler not-active" onClick={() => {
                    $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                    if (window.matchMedia('(min-width: 992px)').matches) {
                        $('body').toggleClass('sidebar-folded');
                    } else if (window.matchMedia('(max-width: 991px)').matches) {
                        $('body').toggleClass('sidebar-open');
                    }
                    $(".sidebar .sidebar-body").on('hover', () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').addClass("open-sidebar-folded");
                        }
                    }, () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').removeClass("open-sidebar-folded");
                        }
                    });
                }}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div className="sidebar-body">
                <ul className="nav">
                    <li className="nav-item">
                        <Link to="/about" className="nav-link">
                            <icon.Info className="link-icon" />
                            <span className="link-title">دەربارە</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/blog" className="nav-link">
                            <icon.Twitch className="link-icon" />
                            <span className="link-title">بلاگ</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/sliders" className="nav-link">
                            <icon.Image className="link-icon" />
                            <span className="link-title">سلاید</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/clients" className="nav-link">
                            <icon.Smile className="link-icon" />
                            <span className="link-title">بریکارەکان</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/general" className="nav-link">
                            <icon.Globe className="link-icon" />
                            <span className="link-title">گشتی</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/projects" className="nav-link">
                            <icon.Server className="link-icon" />
                            <span className="link-title">پرۆژەکان</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/services" className="nav-link">
                            <icon.Layers className="link-icon" />
                            <span className="link-title">خزمەتگوزاری</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/staff" className="nav-link">
                            <icon.Users className="link-icon" />
                            <span className="link-title">ستاف</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Sidebar;