import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";

const Services = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/auth/service');
        setData(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">دەستپێک</a></li>
                    <li className="breadcrumb-item active" aria-current="page">خزمەتگوزاری</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">لیستی خزمەتگوزاریەکان</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">خزمەتگوزاریەکی نوێ</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">خزمەتگوزاریەکی نوێ</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/service`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('زیادکرا');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (کوردی)</label>
                                                            <input type="text" className="form-control" name="title_krd" placeholder="ناونیشان بە کوردی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (عەرەبی)</label>
                                                            <input type="text" className="form-control" name="title_ar" placeholder="ناونیشان بە عەرەبی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناونیشان (ئینگلیزی)</label>
                                                            <input type="text" className="form-control" name="title_en" placeholder="ناونیشان بە ئینگلیزی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (کوردی)</label>
                                                            <textarea className="form-control" name="description_krd" rows={3} placeholder="ناوەڕۆک بە کوردی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (عەرەبی)</label>
                                                            <textarea className="form-control" name="description_ar" rows={3} placeholder="ناوەڕۆک بە عەرەبی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">ناوەڕۆک (ئینگلیزی)</label>
                                                            <textarea className="form-control" name="description_en" rows={3} placeholder="ناوەڕۆک بە ئینگلیزی" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="title" className="form-label">ئایکۆن</label>
                                                            <input type="text" className="form-control" name="icon" placeholder="fa fa-facebook" required />
                                                            <small className="text-muted">دەتوانی ئایکۆنەکان <a href="https://fontawesome.com/v4/icons/" target="_blank" rel="noopener noreferrer"> لێرەوە وەربگری</a></small>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                        <button type="submit" className="btn btn-primary">بڵاوکردن</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>ئایکۆن</th>
                                            <th>سەردێڕ</th>
                                            <th>رێککەوت</th>
                                            <th>بژاردە</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td><i className={item.icon} /></td>
                                                    <td>{item.title_krd}</td>
                                                    <td>{new Date(item.created).toLocaleString()}</td>
                                                    <td>
                                                        <a href={`/blog/${item.id}`} target={'_blank'} rel="noopener noreferrer" className="btn btn-sm btn-outline-primary mx-1" onClick={() => { localStorage.setItem('page', 'blogs'); window.scrollTo(0, 0); }} >
                                                            <icon.Eye size="18" />
                                                        </a>
                                                        <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                            <icon.Edit size="18" />
                                                        </button>
                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => {
                                                            Swal.fire({
                                                                title: 'وریابە !',
                                                                text: "بە سڕینەوەی ئەم داتایە ناتونیت پاشگەزبیتەوە",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'بەلێ, سڕینەوە !',
                                                                cancelButtonText: 'نەخێر, داخستن!'
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    axios.delete(`/service/${item.id}`).then(() => {
                                                                        getData();
                                                                    })
                                                                }
                                                            })
                                                        }}>
                                                            <icon.Trash size="18" />
                                                        </button>
                                                    </td>
                                                    <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="exampleModalLabel">دەستکاریکردن</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                </div>
                                                                <form onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    const formData = new FormData(e.target);
                                                                    const data = Object.fromEntries(formData);
                                                                    axios.put(`/service/${item.id}`, data).then(() => {
                                                                        window.$(`#edit${item.id}`).modal('hide');
                                                                        toast.success('نوێکرایەوە');
                                                                        getData();
                                                                    })
                                                                }} >
                                                                    <div className="modal-body">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناونیشان (کوردی)</label>
                                                                            <input type="text" className="form-control" name="title_krd" defaultValue={item.title_krd} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناونیشان (عەرەبی)</label>
                                                                            <input type="text" className="form-control" name="title_ar" defaultValue={item.title_ar} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناونیشان (ئینگلیزی)</label>
                                                                            <input type="text" className="form-control" name="title_en" defaultValue={item.title_en} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناوەڕۆک (کوردی)</label>
                                                                            <textarea className="form-control" name="description_krd" rows={3} defaultValue={item.description_krd} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناوەڕۆک (عەرەبی)</label>
                                                                            <textarea className="form-control" name="description_ar" rows={3} defaultValue={item.description_ar} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label className="form-label">ناوەڕۆک (ئینگلیزی)</label>
                                                                            <textarea className="form-control" name="description_en" rows={3} defaultValue={item.description_en} required />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="title" className="form-label">ئایکۆن</label>
                                                                            <input type="text" className="form-control" name="icon" defaultValue={item.icon} required />
                                                                            <small className="text-muted">دەتوانی ئایکۆنەکان <a href="https://fontawesome.com/v4/icons/" target="_blank" rel="noopener noreferrer"> لێرەوە وەربگری</a></small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">داخستن</button>
                                                                        <button type="submit" className="btn btn-primary">نوێکردنەوە</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Services;