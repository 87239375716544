import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import * as icon from 'react-feather';
import ErrorPage from './Error';
import APP from '../App';
import Login from './Login';
import About from './Pages/About';
import Blog from './Pages/Blog';
import Clients from './Pages/Clients';
import General from './Pages/General';
import Projects from './Pages/Projects';
import Services from './Pages/Services';
import Staff from './Pages/Staff';
import Sliders from './Pages/Sliders';

const Routes = createBrowserRouter([
    {
        path: "/login", element:
            <div className="main-wrapper">
                <div className="page-wrapper full-page">
                    <Login icon={icon} />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/",
        element: <APP><Blog icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/sliders",
        element: <APP><Sliders icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/about",
        element: <APP><About icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/blog",
        element: <APP><Blog icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/clients",
        element: <APP><Clients icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/general",
        element: <APP><General icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/projects",
        element: <APP><Projects icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/services",
        element: <APP><Services icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/staff",
        element: <APP><Staff icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
]
);

export default Routes;